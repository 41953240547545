import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import neversecondlogo from '../assets/images/neversecondlogo.png'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Digital Tumbleweed - The personal site of Nick Campbell" />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="about" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>A Bit About Me</h2>
                </header>

                <p>
                  I have a family. I am a software developer. I have played many
                  hours of computer games, but tend to find myself working on
                  other interests these days.
                </p>

                <p>
                  Building tools that make it easier for people to accomplish a
                  task or goal is something that I'm passionate about. I love to
                  take complicated information and make it easier to understand.
                  I usually use softawre to accomplish this task.
                </p>

                <p>
                  I'm also a big health and fitness fan. Lifting heavy weight
                  makes me very happy. I played many sports growing up and have
                  always loved being active. Now I mostly go on hikes, practice
                  olympic weightlifting, and participate in Crossfit. But, just
                  because I don't play anymore doesn't mean I enjoy helping
                  others learn and play sports. I love watching my two sons grow
                  and pursue sports as well.
                </p>
              </div>

              <span className="image">
                <img
                  src="https://www.gravatar.com/avatar/795acba3ecda5352f28a3b0cbee3fd16.jpg?s=256"
                  alt=""
                />
              </span>
            </div>
          </section>

          <section id="professional" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Professional</h2>
                </header>

                <p>
                  Professionally I've spent most of my time helping startups and
                  small companies grow. As a part of that work, I've been
                  involved in services for companies like AOL and Paypal, and
                  the research and development of newer technologies at
                  companies like Axiom Software and IT.com.
                </p>

                <p>
                  I've always had an entrepreneurial spirit. Since I was young
                  I've been doing work that people needed help with. Never
                  Second came out of such a thing. Years of my own dealings with
                  health and fitness tools and listening to and working with
                  coaches and trainers has made the platform what it is.
                </p>

                <ul className="alt">
                  <li>
                    <header>
                      <h3>
                        <a
                          href="https://never-second.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Never Second
                        </a>
                      </h3>
                    </header>
                    <p>
                      Never Second is a platform to both help people manage
                      their health and fitness and provide gyms with a platform
                      to better help their members accomplish those goals. Check
                      it out at{' '}
                      <a
                        href="https://never-second.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://never-second.com
                      </a>
                    </p>
                  </li>
                </ul>
              </div>

              <span className="image">
                <a
                  href="https://never-second.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={neversecondlogo} alt="" />
                </a>
              </span>
            </div>
          </section>

          <section id="personal" className="main special">
            <header className="major">
              <h2>Personal</h2>

              <p>
                Not all of my work is paid work. Giving back to the communinity
                that has given a lot to me is something I value. The internet
                runs on open source software and I wanted to contribute to that.
                These are just a few projects that I've created in an attempt to
                give back when I could.
              </p>
            </header>

            <ul className="features">
              <li>
                <a
                  href="https://github.com/kelektiv/node.bcrypt.js"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="alt"
                >
                  <span className="icon major style1 fa-code"></span>
                </a>

                <h3>
                  <a
                    href="https://github.com/kelektiv/node.bcrypt.js"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bCrypt
                  </a>{' '}
                  for{' '}
                  <a
                    href="https://nodejs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Node.JS
                  </a>
                </h3>

                <p>
                  bCrypt is way to hash information that makes it harder for an
                  attacker to steal the content. Often this is used for
                  passwords because once hashed, you can't get the content out.
                </p>
              </li>

              <li>
                <a
                  href="https://github.com/kelektiv/node-cron"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="alt"
                >
                  <span className="icon major style3 fa-copy"></span>
                </a>

                <h3>
                  <a
                    href="https://github.com/kelektiv/node-cron"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cron
                  </a>{' '}
                  for{' '}
                  <a
                    href="https://nodejs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Node.JS
                  </a>
                </h3>
                <p>
                  A cron is a way to schedule a task for execution. The project
                  above allows for that to happen inside a Node process.
                </p>
              </li>

              <li>
                <a
                  href="/blog/pumpkin-spy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="alt"
                >
                  <span className="icon major style5 fa-user-secret" />
                </a>

                <h3>
                  <a
                    href="/blog/pumpkin-spy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PumpkinSpy
                  </a>
                </h3>
                <p>
                  A jack-o-lantern project that my kids and I worked on
                  together.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/projects" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          {/* <section id="second" className="main special">
            <header className="major">
              <h2>Ipsum consequat</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
            <p className="content">
              Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl
              eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum
              ac arcu sit amet, fermentum pellentesque et purus. Integer maximus
              varius lorem, sed convallis diam accumsan sed. Etiam porttitor
              placerat sapien, sed eleifend a enim pulvinar faucibus semper quis
              ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer
              eget purus nec nulla mattis et accumsan ut magna libero. Morbi
              auctor iaculis porttitor. Sed ut magna ac risus et hendrerit
              scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras
              eu ornare dui curabitur lacinia.
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Congue imperdiet</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default Index
