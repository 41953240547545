import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = props => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['about', 'professioonal', 'personal']}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="about">
          <a href="#about">About Me</a>
        </Scroll>
      </li>

      <li>
        <Scroll type="id" element="professional">
          <a href="#professional">Professional</a>
        </Scroll>
      </li>

      <li>
        <Scroll type="id" element="personal">
          <a href="#personal">Personal</a>
        </Scroll>
      </li>

      <li>
        <a href="/blog">Blog</a>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
